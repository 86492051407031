<template>
  <div>
    <div class="mainContent">
      <a-form-model :layout="layout" style="width:100%;" ref="ruleForm" :model="form">
        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="域名" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="es_hosts">
            <!-- <a-input v-model="form.domain" placeholder="请输入域名..." /> -->
            <a-select
              v-model="form.domain"
              show-search
              placeholder="请输入域名"
              :options="web_list"

            >
            </a-select>

          </a-form-model-item>
        </a-col>
        <a-col :sm="24" :md="12" :lg="8" :xl="6" class="lyq_flex_grow">
          <a-form-model-item style="text-align:right;">
            <a-button type="primary" @click="search">
              搜索
            </a-button>
            <a-button @click="reset('ruleForm')">重置</a-button>
          </a-form-model-item>
        </a-col>
      </a-form-model>
    </div>

    <div class="mainContent" >
      <div id="echarts_box"></div>
    </div>
    <div class="mainContent" >

      <div class="tableHeader">
        <div class="title">查询表格</div>
        <div class="operate">

          <a-tooltip placement="top">
            <template slot="title">
              <span>下载</span>
            </template>
            <a class="ant-dropdown-link" style="display:block;margin-right:10px;" @click="start" :disabled="!selectedRowKeys.length > 0">
              <a-icon type="vertical-align-bottom" />
            </a>
          </a-tooltip>

          <a-tooltip placement="top">
            <template slot="title">
              <span>表格大小</span>
            </template>
            <a-dropdown :trigger="['click']" style="margin-right:10px;">
              <a class="ant-dropdown-link" style="display:block">
                <a-icon type="column-height" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a @click="tableSize='default'">默认</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="tableSize='middle'">中等</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="tableSize='small'">紧凑</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </a-tooltip>

        </div>
      </div>

      <a-table
          id="table"
          class="aaaaa"
          :size="tableSize"
          :scroll="{ x: 120,y:600 }"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          :columns="columns"
          :row-key="record => record.date"
          :data-source="data"
          :pagination=false
          :loading="loading"
          bordered
          @change="handleTableChange"
      >
        <template slot="pv_percent" slot-scope="data">
          {{data}}%
        </template>
        <template slot="ip_percent" slot-scope="data">
          {{data}}%
        </template>
        <template slot="uv_percent" slot-scope="data">
          {{data}}%
        </template>
        <template slot="jump" slot-scope="data">
          {{data}}%
        </template>
        <!-- <template slot="操作" slot-scope="data">
              <span>
                <a-button size="small" type="primary" @click="handleView(data.domain)">查看明细</a-button>
              </span>

        </template> -->

      </a-table>
    </div>



  </div>
</template>
<script>
import  {getWebList,dayAnalysisList} from "@/axios/analysis.js"
import {myMixin} from "@/static/js/mixin.js"
import * as echarts from 'echarts';

import moment from "moment";

window.onresize = function () {
    myChart.resize();
  };

let myChart=null;

let totalChart = [//配置图表的数据
    {
      name: 'PV',
      type: 'line',
      encode: {
        x: 0, y: 1
      },
    },
    {
      name: 'IP',
      type: 'line',
      encode: {
        x: 0, y: 2
      },
    },
    {
      name: 'UV',
      type: 'line',
      encode: {
        x: 0, y: 3
      },
    },
  ]

const columns = [//列描述
{
    align:'center',
    title: '日期',
    dataIndex: 'date',
  },
  {
    align:'center',
    title: 'PV',
    dataIndex: 'pv_total',
  },
  {
    align:'center',
    title: 'PV占比',
    dataIndex: 'pv_percent',
    scopedSlots: { customRender: 'pv_percent', },
  },
  {
    align:'center',
    title: 'IP',
    dataIndex: 'ip_total',
  },
  {
    align:'center',
    title: 'IP占比',
    dataIndex: 'ip_percent',
    scopedSlots: { customRender: 'ip_percent', },
  },
  {
    align:'center',
    title: 'UV',
    dataIndex: 'uv_total',
  },
  {
    align:'center',
    title: 'UV占比',
    dataIndex: 'uv_percent',
    scopedSlots: { customRender: 'uv_percent', },
  },
  {
    align:'center',
    title: '跳出率',
    dataIndex: 'jump',
    scopedSlots: { customRender: 'jump', },
  },
  // {
  //   align:'center',
  //   title: 'IP',
  //   dataIndex: 'ip_total_all',
  // },
  // {
  //   align:'center',
  //   title: 'IP',
  //   dataIndex: 'ip_total_all',
  // },
  // {
  //   align:'center',
  //   title: 'IP',
  //   dataIndex: 'ip_total_all',
  // },
  // {
  //   align:'center',
  //   title: 'IP',
  //   dataIndex: 'ip_total_all',
  // },
  // {
  //   align:'center',
  //   title: 'IP',
  //   dataIndex: 'ip_total_all',
  // },


  // {
  //   align:'center',
  //   title: '操作',
  //   // dataIndex: 'status',
  //   scopedSlots: { customRender: '操作', },
  // },
];
export default {
  filters: {

  },
  mixins: [myMixin],
  data() {

    return {
      form: {
        domain:'',
      },

      columns,//列描述
      tableSize:'small',
      data:[],
      web_list:[

      ]
    }

  },
  created() {
  },


  mounted() {
    

    let that=this
    getWebList().then((res)=>{
      this.web_list=res.data.map((item)=>{
        return { 
          value:item.label,
          label:item.label
        }
      })
      this.web_list.unshift({
          value:"",
          label:"全部"
        })
      console.log(this.web_list)
      // web_list=
    }),
    this.fetch();
    setTimeout(() => {
        this.createChart();
    }, 0)
  },

  methods:{
    
    reset(formName){
      this.form.domain=""
      console.log(this.$refs[formName],'this.form')
      this.data = [];
      this.$refs[formName].resetFields();
      this.pagination.current =1;
      this.form.page=1
      this.fetch();
    },
    async fetch (params = {}) {
      this.loading = true;
      console.log('111')
      let data=await dayAnalysisList({...params,...this.form})
      console.log(data,'data')
      this.data=data.data
      this.loading = false;

      let chartData=[];
       for(let i=0;i<data.data.length;i++){
        chartData[i]=data.data[data.data.length-(i+1)]
       }
      myChart.setOption({
          dataset: [
            {
              dimensions: ['date', 'pv_total', 'ip_total', 'uv_total',],//数据的维度
              source: chartData
            },

          ],
        })

    },
    createChart(){
      // 绘制图表
      myChart = echarts.init(document.getElementById('echarts_box'));
      console.log(myChart,'myChart')
      myChart.setOption({
        title: {
            text: '日段分析'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          
        },
        xAxis: { type: 'category',},       
        yAxis: { type: 'value',},
        grid: [
            { top: "14%", left: '4%', width: "94%", height: "80%", },
          ],
        dataset: [
            // {
            //   dimensions: ['date','PVV', 'Ipp', 'UVV',],//数据的维度
            //   source: [
            //     {
            //       date:'aa1',
            //       PVV:'11',
            //       Ipp:'16',
            //       UVV:'35'
            //     },
            //     {
            //       date:'aa2',
            //       PVV:'32',
            //       Ipp:'22',
            //       UVV:'13'
            //     },
            //     {
            //       date:'aa3',
            //       PVV:'22',
            //       Ipp:'37',
            //       UVV:'15'
            //     },
            //     {
            //       date:'aa4',
            //       PVV:'41',
            //       Ipp:'22',
            //       UVV:'30'
            //     },
            //     {
            //       date:'aa5',
            //       PVV:'51',
            //       Ipp:'21',
            //       UVV:'12'
            //     }
            //   ]
            // },

          ],
        series: [
          ...totalChart
        ]
      });
    }



  }
};
</script>
<style lang="scss" scoped>
@import "@/static/css/index.scss";
.ant-table td { white-space: nowrap; }
.ant-table td .greenBtn{background-color:#7ac141;border-color:#7ac141;}
#echarts_box{height:400px;}
</style>


