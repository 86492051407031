
import {get,post} from "@/axios";

export function dayAnalysisList(data) {
    return get('/api/trafficStatistics/dayAnalysis',data);
}

export function getWebList(data) {
    return get('/api/trafficStatistics/siteList',data);
}
